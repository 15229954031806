import Echo from "laravel-echo"

window.Pusher = require('pusher-js');
var token = localStorage.getItem('accessToken');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    encrypted: true,
    auth: {
        headers: {
            Authorization: 'Bearer ' + token
        },
    },
});
