/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  SET_FORMS (state, forms) {
    state.forms = forms
  },
  DUPLICATE_FORM (state, form) {
    console.log('DUPLICATE_FORM')
  },
  REMOVE_RECORD (state, itemId) {
    const index = state.forms.findIndex((u) => u.id === itemId)
    state.forms.splice(index, 1)
  }
}
