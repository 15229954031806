import axios from '@/axios.js'
import {commit} from "lodash/seq";

export default {
  async fetchForm ({commit}, id) {
    const response = await axios.get(route('api.registration-forms.show', {id}))

    console.log('fetchForm() - moduleFormAction.js', response)
    commit('setForm', response.data.data)
    return response
  },
  async fetchSettingsFormulas ({commit, dispatch}) {
    dispatch('fetchAvailableFormulas')
    dispatch('fetchDisplayableFormulas')
  },
  fetchDisplayableFormulas: async function ({commit}) {
    const response = await axios.get('/api/rating-form/fetch-displayable-formulas')
    const result = response.data.map(item => {
      if (item.hasOwnProperty('formula')) {
        return item.formula
      }
    })
    commit('setSettingsSelectedFormulas', result)

    return response
  },
  async storeDisplayableFormulas ({commit, state}) {
    commit('setLoading', true)
    const response = await axios.post('/api/rating-form/store-displayable-formulas', {formulas: state.settingsSelectedFormulas})
      .catch(error => {
        commit('setLoading', false)
        console.error(error.response)
      })
    commit('setLoading', false)

    console.log('storeDisplayableFormulas() - moduleFormActions', response)
    return response
  },
  async fetchAvailableFormulas ({commit}) {
    const response = await axios.get('/api/rating-form/fetch-available-formulas')
    commit('setSettingsDisplayableOptions', response.data.data)

    return response
  },
  async fetchRatingForms ({commit}, {company_id}) {
    const response = await axios.get('/api/rating-form/rating-form-fetch', {
      params: {company_id}
    })

    console.log('fetchRatingForms() - moduleFormActions.js', response)
    commit('setSearchResults', response.data.data)
    return response
  },
  async fetchRatingSubmissions ({commit}, payload) {
    commit('setLoading', true)
    const response = await axios.get('/api/rating-form/rating-form-submissions', { params: {...payload}})

    console.log('fetchRatingForms() - moduleFormActions.js', response)
    commit('setFormSubmissions', response.data.data)
    commit('setLoading', false)
    return response
  },
  async submitForm ({commit}, payload) {
    commit('setLoading', true)
    const response = await axios.post('/api/rating-form/rating-form-submit', {...payload})
      .catch(error => {
        console.log('submitForm().catch', error.response.data)
        return error.response
      })

    commit('setLoading', false)
    console.log('submitForm() - moduleFormActions.js', response)

    if (response.status === 422) {
      for (const [key, value] of Object.entries(response.data.errors)) {
        if(key === 'form_id') {
          this._vm.$vs.notify({
            time: 5000,
            color: 'warning',
            title: 'Validation',
            text: value[0]
          })
        }
      }
    }

    if (response.status === 201 || response.status === 200) {
      commit('addFormSubmission', response.data.data)
    }

    return response
  },
  async destroyFormula ({commit}, payload) {
    let response = null
    commit('setLoading', true)
    if (payload.hasOwnProperty('id')) {
      response = await axios.delete('/api/rating-form/rating-form-formula', {params: {...payload}})
      console.log('Formula destroyed on server', payload)
    }

    commit('setLoading', false)
    console.log('deleteForm() - moduleFormActions.js', response)
    if (response && response.status !== 200) {
      return false
    }
    commit('destroyFormula', payload)

    return response
  },
  async deleteSubmission ({commit}, payload) {
    commit('setLoading', true)
    const response = await axios.delete('/api/rating-form/rating-form-submissions', {params: {...payload}})

    console.log('deleteForm() - moduleFormActions.js', response)
    commit('setLoading', false)
    commit('removeFormSubmission', payload)
    return response
  },
  async fetchFields ({commit}, id) {
    const response = await axios.get(route('api.registration-forms.fields', {id}))

    console.log('fetchFields() - moduleFormActions.js', response)
    commit('setFields', response.data.data)
    return response
  },
  async fetchFormulas ({commit}, {id}) {
    const response = await axios.get(`/api/formula/${id}`)

    console.log('fetchFormula() - moduleFormActions.js')
    commit('setFormulas', response.data.data)
    return response
  },
  async fetchCompanyScales ({commit}, {company_id}) {
    const response = await axios.get('/')
    console.log('fetchCompanyScales', response.data.data)
    commit()

    return response
  },
  async saveFormulas ({commit, state}, formId) {
    const formulas = [...state.formFormulas]

    commit('setLoading', true)
    const response = await axios.post(route('api.formula.store', {formId}), {data: formulas, form_id: formId})
    commit('setLoading', false)

    return response
  }
}
