export default function auth({ next,router }){
  var userInfo = localStorage.getItem('userInfo');
  if(!userInfo ){
    return router.push({ name: 'page-login' });
  }else {
    userInfo = JSON.parse(userInfo);
    if (userInfo.role.name === 'Auditor') {
      return router.push({ name: 'dashboard-analytics' });
    }
  }
  return next();

}
