export default function auth({next, router}) {
  if (!localStorage.getItem('userInfo') ||
    localStorage.getItem('userInfo') &&
    JSON.parse(localStorage.getItem('userInfo')).status !== 'activated') {
    return router.push({name: 'page-login'}).catch(err => {})
  }

  if (localStorage.getItem('createCompanyRedirect') === 'true') {
    localStorage.removeItem('createCompanyRedirect')
    router.push({name: 'create-company'})
  }

  return next();
}
