/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from '../../http/requests/auth/jwt/index.js'

import router from '@/router'
// import {commit} from "lodash/seq";

export default {
  loginAttempt ({ dispatch }, payload) {

    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation
    }

    dispatch('login', newPayload)
  },
  login ({ commit, state, dispatch }, payload) {

    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation()

      payload.notify({
        time: 5000,
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      })

      return false
    }
  },

  updateUsername ({ commit }, payload) {
    payload.user.updateProfile({
      displayName: payload.displayName
    }).then(() => {

      // If username update is success
      // update in localstorage
      const newUserData = Object.assign({}, payload.user.providerData[0])
      newUserData.displayName = payload.displayName
      commit('UPDATE_USER_INFO', newUserData, {root: true})

      // If reload is required to get fresh data after update
      // Reload current page
      if (payload.isReloadRequired) {
        router.push(router.currentRoute.query.to || '/')
      }
    }).catch((err) => {
      payload.notify({
        time: 8800,
        title: 'Error',
        text: err.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    })
  },

  fetchAuthUser({commit}){
    return new Promise((resolve, reject) =>{
      jwt.getAuth().then(res =>{
        commit('UPDATE_USER_INFO', res.data.userData, {root: true})
      }).catch(err =>{
        console.log(err)
      })

    })
  },
  // JWT
  loginJWT (obj, payload) {
    const commit = obj.commit
    return new Promise((resolve, reject) => {
      jwt.login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {

            // Set accessToken
            localStorage.setItem('accessToken', response.data.data.accessToken)

            // Update user details
            commit('UPDATE_USER_INFO', response.data.data.userData, {root: true})
            commit('UPDATE_PRIMARY_COLOR', response.data.data.userData.setting.theme_color, {root: true})

            // Set bearer token in axios
            commit('SET_BEARER', response.data.data.accessToken)
            resolve(response)
        })
        .catch(error => {
          reject(error.response.data.error)
        })
    })
  },

  // UuserInfo
  userSocialInfo (obj, payload) {
    const commit = obj.commit
    commit('UPDATE_USER_INFO', payload.data.userData, {root: true})
    commit('UPDATE_PRIMARY_COLOR', payload.data.userData.setting.theme_color, {root: true})
    // Set bearer token in axios
    commit('SET_BEARER', payload.data.accessToken)
    // Navigate User to homepage
    localStorage.setItem('accessToken', payload.data.accessToken)
    router.push({ name: 'dashboard-analytics' })
  },

  recoverPassword ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      jwt.recoverPassword(payload.email)
        .then(response => {
          // If there's user data in response
          resolve(response);

        })
        .catch(error => { reject(error) })
    })
  },

  resetPassword ({ commit }, payload) {

    return new Promise((resolve, reject) => {
      jwt.resetPassword(payload)
        .then(response => {
          // If there's user data in response
          router.push(router.currentRoute.query.to || '/');
          resolve(response);

        })
        .catch(error => { reject(error) })
    })
  },

  changeProfile({ commit }, payload) {

    return new Promise((resolve, reject) => {

      jwt.changeProfile(payload)
        .then(response => {
          commit('UPDATE_USER_INFO', response.data.data, {root: true})
          resolve(response);
        })
        .catch(error => { reject(error) })
    })

  },

  changeUserInfo({ commit }, payload) {

    return new Promise((resolve, reject) => {

      jwt.changeUserInfo(payload)
        .then(response => {
          commit('UPDATE_USER_INFO', response.data.data, {root: true})
          resolve(response);
        })
        .catch(error => { reject(error) })
    })

  },

  registerUserJWT ({ commit }, payload) {

    const { name, email, password, confirmPassword } = payload.userDetails

    return new Promise((resolve, reject) => {

      // Check confirm password
      if (password !== confirmPassword) {
        reject({message: 'Password doesn\'t match. Please try again.'})
      }

      jwt.registerUser(name, email, password)
        .then(response => {
          // Redirect User
          payload.notify({
            title: 'Account created!',
            text: 'You can login now!',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });

          setTimeout(() => {
            router.push(router.currentRoute.query.to || '/');
          }, 2000)


          // Update data in localStorage
          // localStorage.setItem('accessToken', response.data.accessToken)
          // commit('UPDATE_USER_INFO', response.data.userData, {root: true})

          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  fetchAccessToken () {
    return new Promise((resolve, reject) => {
      jwt.refreshToken()
        .then(response => {
          console.log('SUCCES REFRESH')
          console.log('SUCCES REFRESH')
          resolve(response)
        })
        .catch(function (error) {
          console.log('Error in fetchAccesToken')
          localStorage.removeItem('accessToken')
          localStorage.removeItem('userInfo')
          router.push('/pages/login')
      })
    })
  }
}
