export default {
  computed: {
    authPermissions() {
      let user = JSON.parse(localStorage.getItem('userInfo'))
      if (user && user.permissions) {
        return user.permissions
      }
      return []
    }
  },
  methods: {
    $may (permissionName) {
      let returnAccess = false
      permissionName.forEach(elem => {
        if (this.authPermissions.includes(elem)) {
          returnAccess = true
        }
      })
      return returnAccess
    },
    $canCategory () {

    },

    $canTeam () {
      return Permissions.includes('team-create')
    },
    $canUM () {
      return Permissions.includes('um-create')
    }
  }
}
