import * as _ from 'lodash'
import Vue from 'vue'

const Formula = {
  form_id: null,
  name: null,
  display_name: {
    en: null,
    it: null
  },
  formula: null,
  readonly: false
}

export default {
  setLoading (state, value) {
    state.loading = value
  },
  setForm (state, data) {
    console.log('setForm() - moduleFormMutations.js', data)
    state.form = data
  },
  setSearchResults (state, data) {
    console.log('setSearchResults() - moduleFormMutations', data)
    state.searchResults = data
  },
  setSettingsDisplayableOptions (state, data) {
    console.log('setSettingsDisplayableOptions() - moduleFormMutations', data)
    state.settingsFormulasOptions = data
  },
  setSettingsSelectedFormulas (state, data) {
    console.log('setSettingsDisplayableOptions() - moduleFormMutations', data)
    state.settingsSelectedFormulas = data
  },
  setFormSubmissions (state, data) {
    console.log('setFormSubmissions() - moduleFormMutations', data)
    state.formSubmissions = data
  },
  setSearchResultsValueToSave (state, {value, index, form}) {
    console.log('setSearchResultsValueToSave() - moduleFormMutations')
    const formIndex = state.searchResults.findIndex(element => element === form)
    Vue.set(state.searchResults[formIndex].fields[index], 'valueToSave', value)
    console.log(state.searchResults)
  },
  addFormSubmission (state, data) {
    console.log('addFormSubmission() - moduleFormMutations', data)
    state.formSubmissions.unshift(data)
  },
  removeFormSubmission (state, data) {
    const index = state.formSubmissions.indexOf(data)
    state.formSubmissions.splice(index, 1)
  },

  setFields (state, data) {
    console.log('setFields() - moduleFormMutations.js', data)
    state.formFields = data
  },
  setFormulas (state, data) {
    console.log('setFormulas() - moduleFormMutations.js')
    state.formFormulas = data
  },
  setCompanyScales (state, data) {
    console.log('setCompanyScale', data)
    state.companyScales = data
  },
  addFormula (state) {
    const temp = _.cloneDeep(Formula)
    state.formFormulas.push(temp)
  },
  destroyFormula (state, formula) {
    const index = state.formFormulas.indexOf(formula)
    state.formFormulas.splice(index, 1)
  },
  setFormulaValue (state, {$event, formula}) {
    const index = state.formFormulas.indexOf(formula)
    console.log('setFormulaValue() - moduleFormMutations.js', index, $event)

    state.formFormulas[index].formula = $event
  },
  setFormulaDisplayName (state, {$event, formula, lang}) {
    const index = state.formFormulas.indexOf(formula)
    console.log('setFormulaDisplayName() - moduleFormMutations.js', $event, formula, lang, index)

    state.formFormulas[index].display_name[lang] = $event
  }
}
