/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from '@/axios.js'

//api.registration-forms.index
// route(this.route)
export default {
  fetchIndex ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(route('api.registration-forms.index'))
        .then((response) => {
          commit('SET_FORMS', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchGet (context, companyId) {
    return new Promise((resolve, reject) => {
      axios.get(route('api.registration-forms.show', {id:companyId}))
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  async duplicateForm ({ context }, id) {
    console.log('moduleRegistrationFormManagamentActions.js - duplicateForm ()', id)
    // this._vm.$http.post(`/api/forms/duplicate/${id}`)
    await axios.post(`/api/forms/duplicate/${id}`)
  }
}
