/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import auth from './middleware/auth';
import guest from './middleware/guest';
import verificationEmail from './middleware/verificationEmail';
import createCompanyFirstRedirect from './middleware/createCompanyFirstRedirect'
import blocked from './middleware/blocked.js';
import toBeConfirmed from './middleware/toBeConfirmed.js';
import auditor from "./middleware/auditor";
import axios from './http/axios/index.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      meta:{
        middleware:[ auth , verificationEmail, toBeConfirmed, blocked]
      },
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: '/',
          redirect: '/dashboard/analytics'
        },
        {
          path: '/dashboard/analytics',
          name: 'dashboard-analytics',
          component: () => import('./views/DashboardAnalytics.vue'),
          meta: {
            middleware: [auth],
            can: 'isAuthorized',
            fail: '/pages/login'
          }
        },
        {
          path: '/dashboard/ecommerce',
          name: 'dashboard-ecommerce',
          component: () => import('./views/DashboardECommerce.vue'),
          can: 'isBuyer',
          fail: '/dashboard/analytics'
        },
        {
          path: '/apps/rfq/rfq-list',
          name: 'app-rfq-list',
          component: () => import('@/views/apps/rfq/rfq-list/RFQList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RFQ' },
              { title: 'List', active: true }
            ],
            middleware: [auth],
            pageTitle: 'RFQList',
            can: 'viewRfqs',
            fail: '/dashboard/analytics'
          }
        },
        {
          path: '/apps/rfp/rfp-list',
          name: 'app-rfp-list',
          component: () => import('@/views/apps/rfp/rfp-list/RFPList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RFP' },
              { title: 'List', active: true }
            ],
            middleware: [auth],
            pageTitle: 'rfp_list',
            can: 'viewRfps',
            fail: '/dashboard/analytics'
          }
        },
        {
          path: '/apps/product/product-list',
          name: 'app-product-list',
          component: () => import('@/views/apps/product/product-list/ProductList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Product' },
              { title: 'List', active: true }
            ],
            middleware: [auth],
            pageTitle: 'Product List',
            can: 'viewProducts',
            fail: '/dashboard/analytics'
          }
        },
        {
          path: '/apps/rfq/rfq-edit/:rfqId',
          name: 'app-rfq-edit',
          component: () => import('@/views/apps/rfq/rfq-edit/RFQEdit.vue'),
          meta: {
            middleware: [auth,auditor],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RFQ' },
              { title: 'Edit', active: true }
            ],
            pageTitle: 'RFQEdit',
            can: 'updateRfqs',
            fail: '/dashboard/analytics'
          }
        },
        {
          path: '/apps/rfp/rfp-edit/:rfpId',
          name: 'app-rfp-edit',
          component: () => import('@/views/apps/rfp/rfp-edit/RFPEdit.vue'),
          meta: {
            middleware: [auth,auditor],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'RFP' },
              { title: 'Edit', active: true }
            ],
            pageTitle: 'rfp_edit',
            // TODO: change to edit
            can: 'updateRfps',
            fail: '/dashboard/analytics'
          }
        },
        {
          path: '/apps/project/project-list',
          name: 'app-project-list',
          component: () => import('@/views/apps/project/project-list/ProjectList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Project' },
              { title: 'List', active: true }
            ],
            middleware: [auth],
            pageTitle: 'Project List',
            rule: 'editor'
          }
        },
        {
          path: '/apps/um/um-list',
          name: 'app-um-list',
          component: () => import('@/views/apps/um/um-list/UmList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Um' },
              { title: 'List', active: true }
            ],
            middleware: [auth],
            pageTitle: 'Unit Measurement List',
            rule: 'editor'
          }
        },
        {
          path: '/apps/user/team-list',
          name: 'app-team-list',
          component: () => import('@/views/apps/team/team-list/TeamList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Team' },
              { title: 'List', active: true }
            ],
            middleware: [auth],
            pageTitle: 'Team',
            rule: 'editor'
          }
        },
        {
          path: '/apps/company/company-list',
          name: 'app-company-list',
          component: () => import('@/views/apps/company/company-list/CompanyList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Company' },
              { title: 'List', active: true }
            ],
            middleware: [auth],
            pageTitle: 'company_list',
            rule: 'editor'
          }
        },
        {
          path: '/apps/category/category-list',
          name: 'app-category-list',
          component: () => import('@/views/apps/category/category-list/CategoryList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Category' },
              { title: 'List', active: true }
            ],
            middleware: [auth],
            pageTitle: 'category_list',
            rule: 'editor'
          }
        },
        {
          path: '/apps/user/user-list',
          name: 'app-user-list',
          component: () => import('@/views/apps/user/user-list/UserList.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User' },
              { title: 'List', active: true }
            ],
            middleware: [auth],
            pageTitle: 'user_list',
            can: 'viewUsers',
            fail: '/dashboard/analytics'
          }
        },
        {
          path: '/apps/user/user-view/:userId',
          name: 'app-user-view',
          component: () => import('@/views/apps/user/UserView.vue'),
          meta: {
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User' },
              { title: 'View', active: true }
            ],
            middleware: [auth],
            pageTitle: 'User View',
            rule: 'editor'
          }
        },
        {
          path: '/apps/user/user-edit/:userId',
          name: 'app-user-edit',
          component: () => import('@/views/apps/user/user-edit/UserEdit.vue'),
          meta: {
            middleware: [auth,auditor],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'User' },
              { title: 'Edit', active: true }
            ],
            pageTitle: 'User Edit',
            rule: 'editor'
          }
        },
        {
          path: '/apps/product/product-edit/:productId',
          name: 'app-product-edit',
          component: () => import('@/views/apps/product/product-edit/ProductEdit.vue'),
          meta: {
            middleware: [auth,auditor],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Product' },
              { title: 'Edit', active: true }
            ],
            pageTitle: 'Product Edit',
            can: 'viewProducts',
            fail: '/dashboard/analytics'
          }
        },
        {
          path: '/apps/company/company-edit/:companyId',
          name: 'app-company-edit',
          component: () => import('@/views/apps/company/company-edit/CompanyEdit.vue'),
          meta: {
            middleware: [auth,auditor],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Company' },
              { title: 'Edit', active: true }
            ],
            pageTitle: 'Company Edit',
            can: 'viewCompanies',
            fail: '/dashboard/analytics'
          }
        },
        {
          path: '/pages/profile',
          name: 'page-profile',
          component: () => import('@/views/pages/Profile.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'Profile', active: true }
            ],
            pageTitle: 'Profile',
            rule: 'editor'
          }
        },
        {
          path: '/pages/user-settings',
          name: 'page-user-settings',
          component: () => import('@/views/pages/user-settings/UserSettings.vue'),
          meta: {
            middleware: [auth,auditor],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Pages' },
              { title: 'User Settings', active: true }
            ],
            pageTitle: 'Settings'
          }
        },
        {
          path: '/apps/forms',
          name: 'app-forms-index',
          component: () => import('@/views/apps/form-registration/index/RegistrationFormIndex.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Forms', active:true }
            ],
            pageTitle: 'Forms',
            rule: 'editor'
          }
        },


        {
          path: '/apps/forms/edit/:formId',
          name: 'app-forms-edit',
          component: () => import('@/views/apps/form-registration/edit/RegistrationFormEdit.vue'),
          meta: {
            middleware: [auth,auditor],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Forms', url:'/apps/forms'},
              { title: 'Edit', active:true }
            ],
            pageTitle: 'Forms edit',
            rule: 'editor'
          }
        },
        {
          path: '/apps/profile/my-companies/:companyId',
          name: 'app.profile.my-companies.info',
          component: () => import('@/views/apps/users-company/UserCompanyInfo.vue'),
          meta: {
            middleware: [auth],
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'MyCompanies',active:true }
            ],
            pageTitle: 'Forms',
            rule: 'editor'
          }
        },
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            middleware:[guest]
          }
        },
        {
          path: '/pages/logout',
          name: 'page-logout',
          component: () => import('@/views/pages/logout/Logout.vue'),
          meta: {
            rule: 'editor',
            middleware:[]
          }
        },
        {
          path: '/pages/register',
          name: 'page-register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            middleware:[guest]
          }
        },
        {
          path: '/pages/privacy-policy',
          name: 'page-privacy-policy',
          component: () => import('@/views/pages/privacy-policy/PrivacyPolicy.vue'),
          meta: {
            rule: 'editor',
            middleware:[guest]
          }
        },
        {
          path: '/pages/verification-email',
          name: 'verication-email',
          component: () => import('@/views/pages/verification-email/VerificationEmail.vue'),
          meta: {
            rule: 'editor',
            middleware:[guest]
          }
        },
        {
          path: '/pages/resend-verification-email',
          name: 'resend-verification-email',
          component: () => import('@/views/pages/resend-verification-email/ResendVerificationEmail.vue'),
          meta: {
            rule: 'editor',
            middleware:[guest],
          }
        },
        {
          path: '/pages/create-company',
          name: 'create-company',
          component: () => import('@/views/pages/create-company/CreateCompany.vue'),
          meta: {
            rule: 'editor',
            middleware:[createCompanyFirstRedirect, auth, verificationEmail, toBeConfirmed, blocked],
          }
        },
        {
          path: '/pages/user-not-verifed',
          name: 'user-not-confirmed',
          component: () => import('@/views/pages/user-not-verifed/UserNotVerified.vue'),
          meta: {
            rule: 'editor',
            // middleware:[auth],
          }
        },
        {
          path: '/pages/user-blocked',
          name: 'user-blocked',
          component: () => import('@/views/pages/user-blocked/UserBlocked.vue'),
          meta: {
            rule: 'editor',
            // middleware:[auth],
          }
        },


        {
          path: '/pages/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue')
        },
        {
          path: '/password/reset/:id',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue')
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue')
        },
        {
          path: '/pages/comingsoon',
          name: 'page-coming-soon',
          component: () => import('@/views/pages/ComingSoon.vue')
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue')
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue')
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue')
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue')
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }

  const token = localStorage.getItem('accessToken')
  const userInfo = localStorage.getItem('userInfo')

  if (token && userInfo) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  }
})


// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory (context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  // console.log('router.beforeEach', to, from, next)
  if (to.meta.middleware) {
    let childMiddleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
    };
    let exceptMiddleware = collectExceptMiddleware(to.matched)
    let parentMiddleware = collectParentMiddleware(to.matched);
    let middleware = parentMiddleware.concat(childMiddleware);
    middleware =  removeAllExceptMiddleware(middleware , exceptMiddleware)
    const nextMiddleware = nextFactory(context, middleware, 1);
    if(!middleware[0]) {
      return next();
    }

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next()
})



function collectParentMiddleware($matches, $except = [] ) {
  let parents = [...$matches]
  parents.splice(-1);
  let middlewares = [];
  for (let parent of parents) {
    if(!parent.meta || !parent.meta.middleware){
      continue;
    }
    let parentMiddlewares = Array.isArray(parent.meta.middleware)
      ? parent.meta.middleware
      : [parent.meta.middleware];
    middlewares = middlewares.concat(parentMiddlewares)
  }
  return middlewares;
}

function collectExceptMiddleware(matched) {

  let parents = [ ...matched ]
  // parents.splice(-1);
  let middlewares = [];
  for (let parent of parents) {
    if(!parent.meta || !parent.meta.exceptMiddleware){
      continue;
    }
    let parentMiddlewares = Array.isArray(parent.meta.exceptMiddleware)
      ? parent.meta.exceptMiddleware
      : [parent.meta.exceptMiddleware];
    middlewares = middlewares.concat(parentMiddlewares)
  }
  return middlewares;
}

function removeAllExceptMiddleware(middleware, $excepts) {
  for (let except of $excepts) {
    var i = 0;
    while (i < middleware.length) {
      if (middleware[i] === except) {
        middleware.splice(i, 1);
      } else {
        ++i;
      }
    }
  }
  return middleware
}

export default router
